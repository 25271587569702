<template>
  <div class="exchange-wrap">
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="warp">
        <div class="banner">
          <img src="../../../assets/images/coupon-banner.jpg">
        </div>
        <div class="tab-list">
          <div class="tab" v-for="(item,index) in typeList" :key="index">
            <el-button plain class="tabBtn" :class="activeType==item.type?'is-active':''" @click="typeClick(item.type)">{{item.name}}</el-button>
          </div>
        </div>

        <div class="coupon-list" v-if="couponList.length > 0">
          <div class="coupon" v-for="(item,index) in couponList" :key="index">
            <img :src="item.image">
            <div class="coupon-warp">
              <div class="time title">
                <div class="lable">有效期：</div>
                <div class="value">{{item.date_end}}</div>
              </div>
              <div class="title type">
                <div class="lable">名称：</div>
                <div class="value">{{item.name}}</div>
              </div>
              <div class="title number">
                <div class="lable">券码:</div>
                <div class="value">{{item.record_code}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="coupon-list not" v-else>
          <div class="tip">暂无可兑换商品</div>
        </div>

        <el-pagination layout="prev, pager, next" :total="total_coupon" :page-size="per_page" :current-page="page" @current-change="changePage" :hide-on-single-page="true" class="pagination">
        </el-pagination>
      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
import utils from '@/common/utils'

export default {
  data () {
    return {
      activeType: 1,
      typeList: [
        {
          name: "待使用",
          type: 1,
        },
        {
          name: "已使用",
          type: 2
        },
        {
          name: "已过期",
          type: 3
        },
      ],
      couponList: [],
      page: 1,
      per_page: 8,
      total_coupon: 0,
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    activeType: function (e) {
      if (e) {
        this.getGoodsList();
      }
    },
    page: function (e) {
      if (e) {
        this.getGoodsList();
      }
    }
  },
  created () {
    this.getGoodsList();
  },
  methods: {
    typeClick: utils.debounce(function (e) {
      this.activeType = e
    }, 500, true),
    getGoodsList: function () {
      let that = this;
      let userinfo = that.user_info;
      let status = that.activeType;
      let page = that.page;

      that.$axios.post(that.ocApi + 'coupon/index', {
        telephone: userinfo.telephone,
        status,
        page
      })
        .then(res => {
          that.total_coupon = res.data.total
          that.couponList = res.data.data
        })
    },
    changePage (page) {
      this.page = page
    },

  },
}
</script>

<style lang="scss" scoped>
.exchange-wrap {
  .el-dropdown-link {
    cursor: pointer;
  }
  .pagination {
    text-align: center;
    padding: 20px 0;
    &.el-pagination {
      :deep(button:disabled) {
        background-color: #f7f7f7;
      }
      :deep(.btn-next),
      :deep(.btn-prev) {
        background-color: #f7f7f7;
      }
      :deep(.el-pager li) {
        background-color: #f7f7f7;
      }
    }
  }

  .content {
    background-color: #f7f7f7;
    padding-top: 114px;
    min-height: calc(100vh - 340px);
  }
  .warp {
    width: 1200px;
    margin: auto;
  }
  .warp .banner {
    width: 100%;
  }
  .warp .banner img {
    width: 100%;
  }
  .warp .tab-list {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .warp .tab-list .tab {
    color: #999999;
    font-size: 14px;
    line-height: 14px;
    margin-right: 20px;
    .tabBtn {
      background: inherit;
      &.el-button.is-plain:hover {
        border-color: #dcdfe6;
        color: #606266;
      }
      &.el-button.is-plain:focus {
        border-color: #dcdfe6;
        color: #606266;
        &.is-active {
          color: rgb(0, 192, 135);
          border-color: rgb(0, 192, 135);
        }
      }
    }
  }

  .warp .coupon-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    &.not {
      justify-content: center;
      align-items: center;
      padding: 50px 0;
    }
  }

  .warp .coupon-list .coupon {
    width: 24%;
    background-color: #fff;
    margin-bottom: 20px;
    margin-right: 1.33333%;
  }
  .warp .coupon-list .coupon:nth-of-type(4n) {
    margin-right: 0;
  }
  .warp .coupon-list .coupon img {
    width: 100%;
  }
  .warp .coupon-list .coupon .coupon-warp {
    width: 100%;
    padding: 25px 16px 30px;
    box-sizing: border-box;
  }
  .warp .coupon-list .coupon .coupon-warp .title {
    display: flex;
    font-size: 16px;
    line-height: 16px;
    color: #434343;
    margin-bottom: 9px;
  }
  .warp .coupon-list .coupon .coupon-warp .title .lable {
    margin-right: 5px;
    font-weight: 600;
    width: 66px;
  }
  .warp .coupon-list .coupon .coupon-warp .title .value {
    flex: 1;
  }
}
</style>